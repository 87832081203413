<template>
  <div>
    <div class="head">
      <h4>{{ currentPhase.name }}</h4>
    </div>
    <!-- section body -->
    <div class="wrapper">
      <template v-for="(campaign, index) in list">
        <v-layout :done="isDone" item :key="campaign.id">
          <!-- linha 1 -->
          <v-flex>
            <v-layout w-100 align-start wrap head-item>
              <v-flex grow order-xs2 order-sm1>
                <v-flex>
                  <v-layout align-center>
                    <h3>{{ campaign.name }}</h3>
                    <a
                      v-show="!editingSubdomain[index]"
                      :href="'https://'+buildSubdomainURL(routes.app.CampaignLandingPage, campaign.subdomain) + '?preview=true'"
                      target="_blank"
                      class="link-list"
                    >
                      {{ buildSubdomainURL(routes.app.CampaignLandingPage, campaign.subdomain) }}
                      <a
                        v-show="!editingSubdomain[index]"
                        href="javascript:;"
                        class="fas fa-pencil link-list link-list-edit"
                        @click="changeEditingSubdomain(index)"
                      ></a>
                    </a>

                    <v-flex ml-3 p-relative>
                      <v-layout align-center>
                        <div class="p-absolute display-flex align-center">
                          <InputText
                            v-show="editingSubdomain[index]"
                            v-model="subdomain[index]"
                            type="text"
                            class="slide-left"
                          />
                          <a
                            v-show="editingSubdomain[index]"
                            href="javascript:;"
                            class="fal fa-check link-list f-size-16 slide-left"
                            @click="saveCampaignSubdomain(campaign, index)"
                          ></a>
                          <a
                            v-show="editingSubdomain[index]"
                            href="javascript:;"
                            class="fal fa-times link-list f-size-16 c-red slide-left"
                            @click="changeEditingSubdomain(index, true)"
                          ></a>
                        </div>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <h4>R$ {{ formatterHelper.formatMoney(campaign.capturedValue) }}</h4>
              </v-flex>
              <v-flex shrink order-xs1 order-sm2 tools justify-self-end>
                <div
                  class="pill primary mr-3"
                  title="Total de projetos na campanha"
                >{{ Object.keys(campaign.projectList).length }}</div>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <a
                      class="fal fa-globe-americas mr-3"
                      :class="{active: campaign.published}"
                      v-on="on"
                    ></a>
                  </template>
                  <span v-if="campaign.published">Campanha publicada</span>
                  <span v-if="!campaign.published">Campanha não publicada</span>
                </v-tooltip>

                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, i) in items">
                      <v-list-tile :key="i" v-if="item.condition(campaign)">
                        <v-list-tile-title @click="item.event(campaign)">
                          <i :class="item.icon"></i>
                          {{ item.title }}
                        </v-list-tile-title>
                      </v-list-tile>
                    </template>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- linha 2 -->
          <v-flex mt-2>
            <v-layout w-100 align-center row wrap>
              <v-flex menu shrink pr-2>
                <a class="fal fa-paint-brush" @click="redirectLP(campaign)" title="Editar Campanha"></a>
                <!-- <a class="fal fa-comment-alt-lines" @click="openNews(index)"></a> -->
                <router-link
                  class="fal fa-question-circle"
                  title="FAQ"
                  :to="{ name: routes.app.CampaignFAQ, query: { campaignId: campaign.id }}"
                ></router-link>
                <a
                  class="fal fa-clipboard-list"
                  v-if="campaign.capturedValue > 0 &&  hasPermission([permissions.ConciliationRH], entityId)"
                  @click="campaigReport(campaign.subdomain)"
                  title="Relatório"
                ></a>
                <a
                  class="fas fa-award"
                  v-if="campaign.activeDraw > 0 && hasPermission([permissions.ConciliationRH], entityId)"
                  @click="drawReporter(campaign.subdomain)"
                  title="Relatório Sorteio"
                ></a>
              </v-flex>
              <v-flex money grow xs-12 wrap>
                <v-layout w-100 align-center wrap>
                  <v-flex percent pr-4 slider grow>
                    <v-slider
                      readonly
                      :value="formatterHelper.formatSlider(campaign.capturedValue, campaign.objetiveDonationValue)"
                      thumb-color="#02ACB4"
                      thumb-label="always"
                      color="#02ACB4"
                    ></v-slider>
                  </v-flex>
                  <v-flex money-values shrink>
                    <div>
                      <h4>{{ formatDate(campaign.finalDate) }}</h4>
                      <h3>R$ {{ formatterHelper.formatMoney(campaign.objetiveDonationValue) }}</h3>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex mt-2>
            <NewsEdit
              :showButton="false"
              ref="newsEditComponent"
              v-model="campaign.campaignNewsList"
              :campaignId="campaign.id"
            ></NewsEdit>
          </v-flex>
          <AucAlert
            ref="alertComponent"
            :title="'Confirma a exclusão da campanha ' + campaign.name + ' e todos os seus dados?'"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="confirmDeleteCampaign(campaign.id)"
          />
        </v-layout>
      </template>
      <ModalValueDonation
        v-model="dialog"
        :withClose="true"
        :modalFooter="false"
        myMaxWidth="730px"
        refs
      >
        <v-container fluid pa-0>
          <v-layout pa-0>
            <v-flex list-scroll sm12 style="height: 100%">
              <div class="content-text-block overflow font-sys">
                <v-layout row wrap>
                  <v-flex xs12>
                    <InputMoney
                      textLabel="Qual o valor da doação que deseja somar?"
                      v-model="selectedCampaign.capturedDonationValue"
                      :precision="2"
                    />
                  </v-flex>
                  <v-flex mt-4 t-right>
                    <button class="mr-5 fw-700 c-gray-dark" @click="dialog = !dialog">CANCELAR</button>
                    <button class="fw-700 c-red" @click="updateCapturedDonationValue()">SOMAR</button>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </ModalValueDonation>
    </div>
  </div>
</template>

<script type="plain/text">
import { format } from "date-fns";
import Campaign from "@/scripts/models/campaign.model";
import { CampaignPhases } from "@/scripts/models/enums/campaignPhases.enum";
import CampaignService from "@/scripts/services/campaign.service";
import ReportService from "@/scripts/services/report.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import NewsEdit from "@/components/NewsEdit.vue";
import AucAlert from "@/components/AucAlert.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "@/scripts/helpers/common.helper";
import { mapGetters, mapState } from "vuex";
import ModalValueDonation from "@/components/Modal.vue";
import Permissions from "@/scripts/models/enums/permissions.enum";

export default {
  components: {
    AucAlert,
    NewsEdit,
    ModalValueDonation,
  },
  props: {
    currentPhase: CampaignPhases,
    list: Array,
    entityId: Number
  },
  data() {
    return {
      dialog: false,
      selectedCampaignIndex: 0,
      selectedCampaign: new Campaign(),
      campaignService: new CampaignService(),
      reportService: new ReportService(),
      editingSubdomain: [],
      subdomain: [],
      isDone:
        this.currentPhase.id == CampaignPhases.Encerrada ||
        this.currentPhase.id == CampaignPhases.Cancelada
          ? true
          : false,
      formatterHelper: new FormatterHelper(),
      routes: Routes,
      items: [
        {
          title: "Preview",
          icon: "fal fa-eye",
          condition: function () {
            return true;
          },
          event: function (campaign) {
            window.open(
              "https://" +
                this.buildSubdomainURL(
                  this.routes.app.CampaignLandingPage,
                  campaign.subdomain
                ) +
                "?preview=true",
              "_blank"
            );
          }.bind(this),
        },
        {
          title: "Publicar Campanha",
          icon: "fas fa-globe-americas",
          condition: function (campaign) {
            return !campaign.published;
          },
          event: function (campaign) {
            this.campaignService.publish(campaign.id).then(
              function () {
                this.$emit("refresh");
              }.bind(this)
            );
          }.bind(this),
        },
        {
          title: "Suspender Campanha",
          icon: "fas fa-ban text-red",
          condition: function (campaign) {
            return campaign.published;
          },
          event: function (campaign) {
            this.campaignService.suspend(campaign.id).then(
              function () {
                this.$emit("refresh");
              }.bind(this)
            );
          }.bind(this),
        },
        {
          title: "Editar",
          icon: "fal fa-pencil",
          condition: function (campaign) {
            if (
              (campaign.campaignPhaseId == CampaignPhases.Encerrada ||
                campaign.campaignPhaseId == CampaignPhases.Cancelada) &&
              !this.hasPermission([Permissions.CRUDCampaignAll])
            )
              return false;
            else {
              return true;
            }
          }.bind(this),
          event: function (campaign) {
            this.$router.push({
              name: Routes.app.CampaignEdit,
              query: { campaignId: campaign.id },
            });
          }.bind(this),
        },
        // {
        //   title: "Clonar",
        //   icon: "fal fa-copy",
        //   condition: function(campaign) {
        //     return false;
        //   },
        //   event: ""
        // },
        {
          title: "Excluir",
          icon: "fal fa-trash",
          condition: function (campaign) {
            if (
              campaign.campaignPhaseId == CampaignPhases.Encerrada ||
              campaign.campaignPhaseId == CampaignPhases.Cancelada
            )
              return false;
            else {
              return true;
            }
          },
          event: function (campaign) {
            var index = this.list.findIndex((x) => x.id == campaign.id);
            this.$refs.alertComponent[index].open();
          }.bind(this),
        },
        {
          title: "Somar doação avulsa",
          icon: "fal fa-envelope-open-dollar",
          condition: function (campaign) {
            return true;
          },
          event: function (campaign) {
            this.dialog = true;
            this.selectedCampaign = campaign;
          }.bind(this),
        },
      ],
    };
  },
  created() {
    this.permissions = Permissions
  },
  computed: {
    ...mapGetters(["hasPermission"]),
  },
  watch: {
    list: {
      immediate: true,
      handler(val, oldVal) {
        if (val != null && val instanceof Array) {
          this.editingSubdomain = [];
          for (let i = 0; i < val.length; i++) {
            this.editingSubdomain.push(false);
            this.subdomain.push(val[i].subdomain);
          }
        }
      },
    },
  },
  methods: {
    saveCampaignSubdomain(campaign, index) {
      this.selectedCampaignIndex = index;
      this.campaignService
        .saveCampaignSubdomain(campaign.id, this.subdomain[index])
        .then(this.campaignService_saveCampaignSubdomainCallback);
    },
    campaignService_saveCampaignSubdomainCallback(data) {
      if (data) {
        this.list[this.selectedCampaignIndex].subdomain = this.subdomain[
          this.selectedCampaignIndex
        ];
        this.changeEditingSubdomain(this.selectedCampaignIndex);
      } else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Já existe uma campanha com esse subdomínio"
        );
      }
    },
    changeEditingSubdomain(index, copy = false) {
      this.$set(this.editingSubdomain, index, !this.editingSubdomain[index]);
      if (!this.editingSubdomain[index] && copy)
        this.subdomain[this.selectedCampaignIndex] = this.list[
          this.selectedCampaignIndex
        ].subdomain;
    },
    updateCapturedDonationValue() {
      this.campaignService
        .updateCapturedDonationValue(
          this.selectedCampaign.id,
          this.selectedCampaign.capturedDonationValue
        )
        .then(
          function () {
            this.$emit("refresh");
          }.bind(this)
        );
      this.dialog = false;
    },
    buildSubdomainURL(route, subdomain) {
      let nextRoute = this.$router.resolve({
        name: route,
      });

      let hostWithSubdomain = window.location.host;

      let currentSubdomain = CommonHelper.getSubdomain();
      if (currentSubdomain != null)
        hostWithSubdomain = window.location.host.split(".").slice(1).join(".");

      return subdomain + "." + hostWithSubdomain + nextRoute.href;
    },
    redirectLP(campaign) {
      let routeData = this.$router.resolve({
        name: this.routes.app.CampaignLandingPageEdit,
        query: { campaignId: campaign.id, entityId: this.entityId, withoutCarts: true },
      });
      window.open(routeData.href, "_blank");
    },
    openBlob(response, name, type) {
      if (response == null) return;
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        name + "_" + this.$moment().format("YYYY-MM-DD_H:m") + ".xlsx"
      );
      document.body.appendChild(link);
      link.click();
    },
    drawReporter(campaignName) {
      this.reportService.drawReport(campaignName).then((response) => {
        this.openBlob(response, "Relatorio_Sorteio_" + campaignName, "application/excel");
      });
    },
    campaigReport(campaignName) {
      this.reportService.geralReportByEntity(null, null, campaignName).then((response) => {
        this.openBlob(response, "Relatorio_Campanha_" + campaignName, "application/excel");
      });
    },
    openNews(index) {
      this.$refs.newsEditComponent[index].open();
    },
    confirmDeleteCampaign(campaignId) {
      this.campaignService.deleteCampaign(campaignId).then(
        function () {
          this.$emit("refresh");
        }.bind(this)
      );
    },
    formatDate(currentDate) {
      if (currentDate === null) {
        return "Indefinido";
      } else {
        return format(currentDate, "DD MMM YYYY", {
          locale: require("date-fns/locale/pt"),
        });
      }
    },
  },
};
</script>